<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <h2>Ryanne van Rossen</h2>
          <p>
            Na mijn studie fysiotherapie te hebben afgerond ben ik al snel op
            zoek gegaan naar meer verdieping, dat vond ik in het vak
            osteopathie. Voor mij bracht deze studie veel ontbrekende
            puzzelstukjes en geeft het mij nog meer mogelijkheden om iemand te
            begeleiden in herstel. De mens als geheel zien, en daarom ook als
            geheel behandelen betekent verder kijken dan de klacht waarmee
            iemand binnenkomt. Dat verder kijken heeft mij tevens richting
            diverse cursussen gebracht om meer te leren over psychologie en
            gedrag.
          </p>
          <p>
            Ik ben een groot liefhebber van het strand, weer of geen weer, daar
            kun je me vinden op een vrije dag. Sinds een paar jaar ben ik
            begonnen met golfsurfen, dit doe ik naast yoga met grote regelmaat.
            Een andere grote hobby van mij is thee, en alles wat daarbij komt
            kijken.
          </p>
          <p>
            Naast de behandeling van klachten als verteringsproblematiek,
            (chronische) rugklachten en hoofdpijn, kun je bij mij ook goed
            terecht met burnout- of stressgerelateerde klachten, PTSS of
            klachten na emotioneel trauma.
          </p>
          <img src="../../assets/team/ryanne.png" alt="Ryanne" />
        </Container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Container from "../../components/Container.vue";
export default {
  name: "Ryanne",
  components: { Container }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

h3 {
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  color: #747769;
  em {
    font-style: normal;
    color: #747769;
  }
}

img {
  max-width: 100%;
}
</style>
